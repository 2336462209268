import React, { useState } from "react";
import { Button } from "react-bootstrap"
import ModalTiny from "react-bootstrap/Modal"
import HTMLReactParser from 'html-react-parser';
import { Link } from "@StarberryUtils";
import { CloseIcon } from "../Icons/Icons";
import "./Popup.scss"

const Popup = ({ show, onClose, popupData }) => {
    if (!popupData) return null;

    const ctaLink = popupData.CTA_Link || (popupData.Use_Default_CTA_Link ? popupData.Default_CTA_Link : "");
   
    return (
        <ModalTiny
            show={show}
            onHide={onClose}
            dialogClassName="popup-modal"
            backdrop="static"
            aria-labelledby="popup-modal-title"
            keyboard={false}
            centered
            className="modal-form-wrapper popup-modal-wrapper"
        >
            <ModalTiny.Header>
            {popupData?.Image?.url ?
                <div className="popup-img img-zoom">
                        <img loading="lazy" src={popupData?.Image?.url} alt={popupData?.Image?.alternativeText ? popupData?.Image?.alternativeText : `Dacha - ${popupData?.Heading}`} className="img-fluid popup-image" />
                </div>
                        
                : "" }
            </ModalTiny.Header>
            <ModalTiny.Body closeButton className="popup-content">
                    <Button variant="none" className="closeIcon" onClick={onClose} aria-label="Close">
                        <CloseIcon />
                    </Button>
                    {popupData.Heading &&
                        <h4>{popupData.Heading}</h4>
                    }
                     {popupData.Content &&
                        <div className="module-text">
                            {HTMLReactParser(popupData.Content.replace(/\n/g, ''))}
                        </div>
                    }   
                    {ctaLink &&
                    <div className="btn-wrapper">
                        <Link to={ctaLink} className="btn btn-primary" onClick={onClose}>{popupData?.CTA_Label || 'Contact Us'}</Link>
                    </div>
                    }
            </ModalTiny.Body>
        </ModalTiny>
    );
};

export default Popup;