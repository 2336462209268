import React, { useState, useEffect } from "react"
import { useMatch } from "@reach/router"
import { Container, Row } from 'react-bootstrap';
import Homepage from "../components/Home/Banner/Banner";
import PlainHeader from "../components/Home/Banner/PlainHeader";
import axios from "axios";
import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL, VIDEOS_PAGE_URL } from "../components/common/site/constants";
import Loadable from "@loadable/component"
import Hero from "./Hero/Hero";
import FeaturedProperties from "./Home/Properties/FeaturedProperties";

const Counter = Loadable(() => import("../components/Home/Counter/CounterHome"))
const InformationList = Loadable(() => import("../components/Home/Information/InformationList"))
const Breadcrumb = Loadable(() => import("../components/Home/Breadcrumb/Breadcrumb"))
const About = Loadable(() => import("../components/Home/AboutUs/About"))
const Reviews = Loadable(() => import("../components/Home/Reviews/ReviewsHome"));
const Contact = Loadable(() => import("../components/Home/ContactUs/Contact"));
const LatestNews = Loadable(() => import("../components/Home/News/LatestNews"))
const ServiceCards = Loadable(() => import("../components/Home/ServiceCards/ServiceCards"))
const Guide = Loadable(() => import("../components/Home/Guide/Guide"))


const Modules = (props) => {
    const NewsCategory = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:item/");
    const [sidebar, setSidebar] = useState(false);
    const [renderComponent, setRenderComponent] = useState(true);
    let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null
    let [latestReview, setReviews] = useState([]);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    let [reviewCount, setReviewCount] = React.useState(0);
    let [averageRating, setAverageRating] = React.useState(0);
    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
        window.addEventListener("scroll", () => {
            setSidebar(window.scrollY > 0)
        })

        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

        if (props.page.Pagename !== "Home") {
            setRenderComponent(true)
        }
        // if(renderComponent == true){
            if( latestReview.length == 0){
                getitems(url);
              }
        //   }
    }, [])
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    const getitems = async url => {
        try {
            const { data } = await axios.get(url,strapiconfig)// could be from env files  
            if (data) {
                const reviews = data.filter(rev => rev.comment != null && rev.starRating == "FIVE");
                reviews.sort((a, b) => b.updateTime - a.updateTime);
                setReviewCount(data.length);
                let sumOfScores = 0;
                data.map((review) => {
                    const reviewStringValue = review.starRating;
                    sumOfScores += wordToNumber[reviewStringValue];
                })
                setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
                setReviews(reviews.slice(0, 10));
            }

        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }
    return (
        <div className={language === 'Russian' ? "russian-page-modules page-modules" : "page-modules"}>            
            {
                props.page.Pagename !== "Home" &&
                <Breadcrumb data={props.page.all_menus ? props.page.all_menus[0] : ''} page={props.page.Pagename} />
            }
            {props.page.Banner_Image && props.page.Layout !== 'Static' && props.page.Header_Style !== "White_Background" &&
            <>
                
                <Hero id={props.page._id} imagetransforms={props.page.imagetransforms} Title={props.page.Header_Title} content={props.page.Header_Content} image={props.page.Banner_Image} info={props.page.Header_Contact_Info} cta_1={props.page.Header_CTA_1_Label} cta_2={props.page.Header_CTA_2_Label} cta_1_link={props.page.Header_CTA_1_Link} cta_2_link={props.page.Header_CTA_2_Link} search={props.page.Show_Search_Type} rating={props.page.Show_Google_Rating} video={props.page.Video_URL} mobile_video={props.page.Mobile_Video} timings={props.site.Available_Timings} pagename={props.page.Pagename} layout={props.page.Layout} />
                
                {/* <Homepage id={props.page._id} imagetransforms={props.page.imagetransforms} content={props.page.Header_Content} image={props.page.Banner_Image} info={props.page.Header_Contact_Info} cta_1={props.page.Header_CTA_1_Label} cta_2={props.page.Header_CTA_2_Label} cta_1_link={props.page.Header_CTA_1_Link} cta_2_link={props.page.Header_CTA_2_Link} search={props.page.Show_Search_Type} rating={props.page.Show_Google_Rating} video={props.page.Video_URL} mobile_video={props.page.Mobile_Video} timings={props.site.Available_Timings} pagename={props.page.Pagename} layout={props.page.Layout} /> */}
                </>
            }
            {props.page.Header_Content && props.page.Banner_Image == null && props.page.Layout !== 'Static' && props.page.Layout !== 'News_Landing' && props.page.Header_Style !== "White_Background" && props.page.Layout !== 'Videos_Landing' &&
                <PlainHeader content={props.page.Header_Content} info={props.page.Header_Contact_Info} cta_1={props.page.Header_CTA_1_Label} cta_2={props.page.Header_CTA_2_Label} cta_1_link={props.page.Header_CTA_1_Link} cta_2_link={props.page.Header_CTA_2_Link} search={props.page.Show_Search_Type} rating={props.page.Show_Google_Rating} video={props.page.Video_URL} page={props.page.Pagename} />
            }

            {
                props.modules && props.modules.map((module, i) => {
                    return (
                        <>
                            { module.Place &&                                
                                <FeaturedProperties place={module.Place} searchType={module.Search_Type} pagename={props.page.Pagename} />
                            }

                            {renderComponent && module.Add_Service &&
                                <About service={module.Add_Service} id={props.page._id} imagetransforms={props.page.imagetransforms} />

                            }
                            {module.Add_Service && props.page.Layout === 'Service_Landing' && props.page.Useful_Information === true &&
                                <Container className={`static-content-landing pad-50`}>
                                    <Row>
                                        <div className={`col-xl-12  InformationList`}>
                                            <InformationList />
                                        </div>
                                    </Row>
                                </Container>

                            }

                            {module.type === "GLSTRAPI_ComponentModuleFooterBlock" &&
                                <Contact {...module} id={props.page._id} imagetransforms={props.page.imagetransforms} />
                            }
                            {module.type === "GLSTRAPI_ComponentComponentsGuideModule" &&
                                <Guide {...module} id={props.page._id} imagetransforms={props.page.imagetransforms} />
                            }
                            {renderComponent && module.Reviews_Background_Image &&
                                <Reviews page={props.page.Pagename} image={module.Reviews_Background_Image} reviews={latestReview} reviewCount={reviewCount} averageRating={averageRating} id={props.page._id} imagetransforms={props.page.imagetransforms} />
                            }

                            { module.Add_Stat &&
                                <Counter stats={module.Add_Stat} page={props.page.Pagename} reviews={latestReview} reviewCount={reviewCount} averageRating={averageRating}/>
                            }
                            {  module.Select === 'Latest_News_and_Insights' &&
                                    <LatestNews />
                            }
                             {module?.Service_Cards && module?.DisplayType === "FAQ" &&
                                    <ServiceCards data={module.Service_Cards} page={props.page.Pagename}/>
                            }
                          
                        </>
                    )
                })
            }
        </div >
    )

}

export default Modules